import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import component from 'omniscient';
import observer from 'omnipotent/decorator/observer';
import { CSSTransition } from 'react-transition-group';
import { debounce } from 'throttle-debounce';
import { UnmountClosed as Collapse } from 'react-collapse';

import { parse } from '../../utils/queryString';
import { structure } from '../../core';
import SearchSortingOptionsSelect from '../../components/SearchSortingOptionsSelect/SearchSortingOptionsSelect';
import DatePickerPopover from '../../components/Popovers/DatePickerPopover';
import Sidebar from '../../components/Sidebar/Sidebar';
import SmartScrollView from '../../components/ScrollView/SmartScrollView';
import Checkbox from '../../components/Checkbox/Checkbox';
import Button from '../../components/Button/Button';
import SearchBar from '../../components/SearchBar/SearchBar';
import SearchResultSpeechMoments from '../../components/SearchResultSpeechMoments/SearchResultSpeechMoments';
import CircularProgress from '../../components/Progress/CircularProgress';
import Today from '../../icons/Today';
import Close from '../../icons/Close';
import { ExpandLess, ExpandMore } from '../../icons';
import Tune from '../../icons/Tune';
import ArrowDown from '../../icons/ArrowDown';
import ArrowUp from '../../icons/ArrowUp';
import ScrollToTopButton from '../../components/ScrollToTopButton/ScrollToTopButton';
import SkipLink from '../../components/SkipLink/SkipLink';
import InternalLink from '../../components/InternalLink/InternalLink';

const SHOW_LESS_COUNT = 4;

/**
 * AboutComponent definition.
 */
const definition = {
  smartScrollViewRef: React.createRef(),
  sidebarRef: React.createRef(),

  getInitialState: () => ({
    loadingResults: false,
    loadingMore: false,

    datePickerOpen: false,
    datePickerAnchor: null,
    datePickerValue: null,
    datePickerKey: null,

    datesOpen: true,
    statusOpen: true,
    categoriesOpen: true,
    partiesOpen: true,
    politiciansOpen: true,
    dossiersOpen: true,
    locationsOpen: true,
    showMoreCategories: false,
    showMoreParties: false,
    showMorePoliticians: false,
    showMoreDossiers: false,
    showMoreLocations: false,

    scrollButtonVisible: false,
    matchesMobileMediaQuery: false,

    sortBy: null,
  }),

  contextTypes: {
    getCursor: PropTypes.func.isRequired,
    getService: PropTypes.func.isRequired,
    pathTo: PropTypes.func.isRequired,
  },

  _generateFiltersContent: function () {
    const {
      datesOpen,
      statusOpen,
      categoriesOpen,
      partiesOpen,
      politiciansOpen,
      dossiersOpen,
      locationsOpen,
      showMoreCategories,
      showMoreParties,
      showMorePoliticians,
      showMoreDossiers,
      showMoreLocations,
    } = this.state;

    const filters = this.props.aggregations.deref();

    const DateToggleIcon = datesOpen ? ExpandLess : ExpandMore;
    const CategoryToggleIcon = categoriesOpen ? ExpandLess : ExpandMore;
    const PartiesToggleIcon = partiesOpen ? ExpandLess : ExpandMore;
    const PoliticiansToggleIcon = politiciansOpen ? ExpandLess : ExpandMore;
    const DossiersToggleIcon = dossiersOpen ? ExpandLess : ExpandMore;
    const StatusToggleIcon = statusOpen ? ExpandLess : ExpandMore;
    const LocationsToggleIcon = locationsOpen ? ExpandLess : ExpandMore;

    const categoryNames = filters?.categoryNames?.categoryNames || filters?.categoryNames;
    const partyShorthands = filters?.partyShorthands?.partyShorthands || filters?.partyShorthands;
    const politicianNames = filters?.politicianNames?.politicianNames || filters?.politicianNames;
    const dossierIds = filters?.dossierIds?.dossierIds || filters?.dossierIds;
    const locationNames = filters?.locationName?.locationName || filters?.locationName;

    return (
      <aside>
        <form id="search-filters">
          <h2 className="srOnly u-sm-invisible">Verfijn uw resultaten</h2>
          <fieldset form="search-filters" name="datum">
            <div className="SearchPage-header u-bordered u-borderBottom">
              <legend className="SearchPage-heading">
                <h3 className="Heading">Datum</h3>
              </legend>
              <Button
                className="IconButton IconButton--no-padding-right"
                onClick={() => this.setState({ datesOpen: !this.state.datesOpen })}
                aria-label={'Datum filters ' + (this.state.datesOpen ? 'inklappen' : 'uitklappen')}
                aria-expanded={this.state.datesOpen}
                aria-controls="datum-filter"
              >
                <DateToggleIcon />
              </Button>
            </div>
            {
              <Collapse className="icon-no-padding-right" isOpened={this.state.datesOpen} fixedHeight={381}>
                <div id="datum-filter">
                  {this._generateDateFilters()}
                  {this._getDatePicker()}
                </div>
              </Collapse>
            }
          </fieldset>
          <fieldset form="search-filters" name="status">
            <div className="SearchPage-header u-bordered u-borderBottom">
              <legend className="SearchPage-heading">
                <h3 className="Heading">Status</h3>
              </legend>
              <Button
                className="IconButton IconButton--no-padding-right"
                onClick={() => this.setState({ statusOpen: !this.state.statusOpen })}
                aria-label={'Status filters ' + (this.state.statusOpen ? 'inklappen' : 'uitklappen')}
                aria-expanded={this.state.statusOpen}
                aria-controls="status-filter"
              >
                <StatusToggleIcon />
              </Button>
            </div>
            <Collapse isOpened={this.state.statusOpen}>
              <div id="status-filter">{this._getStatusFilters()}</div>
            </Collapse>
          </fieldset>

          {locationNames?.buckets && (
            <fieldset form="search-filters" name="locatie">
              <div className="SearchPage-header u-bordered u-borderBottom">
                <legend className="SearchPage-heading">
                  <h3 className="Heading">Zalen</h3>
                </legend>
                <Button
                  className="IconButton IconButton--no-padding-right"
                  onClick={() => this.setState({ locationsOpen: !this.state.locationsOpen })}
                  aria-label={'Zaal filters ' + (this.state.locationsOpen ? 'inklappen' : 'uitklappen')}
                  aria-expanded={this.state.locationsOpen}
                  aria-controls="locatie-filter"
                >
                  <LocationsToggleIcon />
                </Button>
              </div>
              {
                <Collapse isOpened={this.state.locationsOpen}>
                  <div id="locatie-filter">
                    {this._getLocationFilters()}
                    {locationNames.buckets.length >= SHOW_LESS_COUNT && (
                      <React.Fragment>
                        {showMoreLocations ? (
                          <Button
                            className="Button--small Button--link"
                            aria-controls="locatie-filter"
                            aria-expanded={true}
                            onClick={() => this.setState({ showMoreLocations: false })}
                          >
                            <ArrowUp className="Button-icon" /> <span className="Button-label">Minder</span>
                          </Button>
                        ) : (
                          <Button
                            className="Button--small Button--link"
                            aria-controls="locatie-filter"
                            aria-expanded={false}
                            onClick={() => {
                              this.setState({ showMoreLocations: true }, () => this._focusFirstHiddenCheckbox('#locatie-filter', 'locationNames'));
                            }}
                          >
                            <ArrowDown className="Button-icon" /> <span className="Button-label">Meer</span>
                          </Button>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </Collapse>
              }
            </fieldset>
          )}

          {categoryNames?.buckets && (
            <fieldset form="search-filters" name="categorieën">
              <div className="SearchPage-header u-bordered u-borderBottom">
                <legend className="SearchPage-heading">
                  <h3 className="Heading">Categorieën</h3>
                </legend>
                <Button
                  className="IconButton IconButton--no-padding-right"
                  onClick={() => this.setState({ categoriesOpen: !this.state.categoriesOpen })}
                  aria-label={'Categorieën filters ' + (this.state.categoriesOpen ? 'inklappen' : 'uitklappen')}
                  aria-expanded={this.state.categoriesOpen}
                  aria-controls="categorie-filter"
                >
                  <CategoryToggleIcon />
                </Button>
              </div>
              {
                <Collapse isOpened={this.state.categoriesOpen}>
                  <div id="categorie-filter">
                    {this._getSubjectFilters()}
                    {categoryNames.buckets.length >= SHOW_LESS_COUNT && (
                      <React.Fragment>
                        {showMoreCategories ? (
                          <Button
                            className="Button--small Button--link"
                            aria-controls="categorie-filter"
                            aria-expanded={true}
                            onClick={() => this.setState({ showMoreCategories: false })}
                          >
                            <ArrowUp className="Button-icon" /> <span className="Button-label">Minder</span>
                          </Button>
                        ) : (
                          <Button
                            className="Button--small Button--link"
                            aria-controls="categorie-filter"
                            aria-expanded={false}
                            onClick={() => {
                              this.setState({ showMoreCategories: true }, () => this._focusFirstHiddenCheckbox('#categorie-filter', 'categoryNames'));
                            }}
                          >
                            <ArrowDown className="Button-icon" /> <span className="Button-label">Meer</span>
                          </Button>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </Collapse>
              }
            </fieldset>
          )}
          {partyShorthands?.buckets && (
            <fieldset form="search-filters" name="partijen">
              <div className="SearchPage-header u-bordered u-borderBottom">
                <legend className="SearchPage-heading">
                  <h3 className="Heading">Partijen</h3>
                </legend>
                <Button
                  className="IconButton IconButton--no-padding-right"
                  onClick={() => this.setState({ partiesOpen: !this.state.partiesOpen })}
                  aria-label={'Partijen filters ' + (this.state.partiesOpen ? 'inklappen' : 'uitklappen')}
                  aria-expanded={this.state.partiesOpen}
                  aria-controls="partij-filter"
                >
                  <PartiesToggleIcon />
                </Button>
              </div>
              {
                <Collapse isOpened={this.state.partiesOpen}>
                  <div id="partij-filter">
                    {this._getPartyFilters()}
                    {partyShorthands.buckets.length >= SHOW_LESS_COUNT && (
                      <React.Fragment>
                        {showMoreParties ? (
                          <Button className="Button--small Button--link" onClick={() => this.setState({ showMoreParties: false })}>
                            <ArrowUp className="Button-icon" /> <span className="Button-label">Minder</span>
                          </Button>
                        ) : (
                          <Button
                            className="Button--small Button--link"
                            aria-controls="partij-filter"
                            aria-expanded={false}
                            onClick={() => {
                              this.setState({ showMoreParties: true }, () => this._focusFirstHiddenCheckbox('#partij-filter', 'partyShorthands'));
                            }}
                          >
                            <ArrowDown className="Button-icon" /> <span className="Button-label">Meer</span>
                          </Button>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </Collapse>
              }
            </fieldset>
          )}
          {politicianNames?.buckets && (
            <fieldset form="search-filters" name="politici">
              <div className="SearchPage-header u-bordered u-borderBottom">
                <legend className="SearchPage-heading">
                  <h3 className="Heading">Politici</h3>
                </legend>
                <Button
                  className="IconButton IconButton--no-padding-right"
                  onClick={() => this.setState({ politiciansOpen: !this.state.politiciansOpen })}
                  aria-label={'Politici filters ' + (this.state.politiciansOpen ? 'inklappen' : 'uitklappen')}
                  aria-expanded={this.state.politiciansOpen}
                  aria-controls="politici-filter"
                >
                  <PoliticiansToggleIcon />
                </Button>
              </div>
              {
                <Collapse isOpened={this.state.politiciansOpen}>
                  <div id="politici-filter">
                    {this._getPoliticianFilters()}
                    {politicianNames.buckets.length >= SHOW_LESS_COUNT && (
                      <React.Fragment>
                        {showMorePoliticians ? (
                          <Button
                            className="Button--small Button--link"
                            aria-controls="politici-filter"
                            aria-expanded={false}
                            onClick={() => this.setState({ showMorePoliticians: false })}
                          >
                            <ArrowUp className="Button-icon" /> <span className="Button-label">Minder</span>
                          </Button>
                        ) : (
                          <Button
                            className="Button--small Button--link"
                            aria-controls="politici-filter"
                            aria-expanded={true}
                            onClick={() => {
                              this.setState({ showMorePoliticians: true }, () => this._focusFirstHiddenCheckbox('#politici-filter', 'politicianNames'));
                            }}
                          >
                            <ArrowDown className="Button-icon" /> <span className="Button-label">Meer</span>
                          </Button>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </Collapse>
              }
            </fieldset>
          )}
          {dossierIds?.buckets && (
            <fieldset form="search-filters" name="dossiers">
              <div className="SearchPage-header u-bordered u-borderBottom">
                <legend className="SearchPage-heading">
                  <h3 className="Heading">Dossiers</h3>
                </legend>
                <Button
                  className="IconButton IconButton--no-padding-right"
                  onClick={() => this.setState({ dossiersOpen: !this.state.dossiersOpen })}
                  aria-label={'Dossiers filters ' + (this.state.dossiersOpen ? 'inklappen' : 'uitklappen')}
                  aria-expanded={this.state.dossiersOpen}
                  aria-controls="dossiers-filter"
                >
                  <DossiersToggleIcon />
                </Button>
              </div>
              {
                <Collapse isOpened={this.state.dossiersOpen} id="dossiers-filter">
                  <div id="dossiers-filter">
                    {this._getDossierFilters()}
                    {dossierIds.buckets.length >= SHOW_LESS_COUNT && (
                      <React.Fragment>
                        {showMoreDossiers ? (
                          <Button
                            className="Button--small Button--link"
                            aria-controls="dossiers-filter"
                            aria-expanded={false}
                            onClick={() => this.setState({ showMoreDossiers: false })}
                          >
                            <ArrowUp className="Button-icon" /> <span className="Button-label">Minder</span>
                          </Button>
                        ) : (
                          <Button
                            className="Button--small Button--link"
                            aria-controls="dossiers-filter"
                            aria-expanded={true}
                            onClick={() => {
                              this.setState({ showMoreDossiers: true }, () => this._focusFirstHiddenCheckbox('#dossiers-filter', 'dossierIds'));
                            }}
                          >
                            <ArrowDown className="Button-icon" /> <span className="Button-label">Meer</span>
                          </Button>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </Collapse>
              }
            </fieldset>
          )}
        </form>
      </aside>
    );
  },

  /**
   * Focus the first previously hidden checkbox for improved accessibility
   *
   * @param {string} containerElement
   * @param {string} filterName
   */
  _focusFirstHiddenCheckbox(containerElement, filterName) {
    const activeFilters = this.props.activeFilters.deref();
    const activeItems = activeFilters.get(filterName);
    const visibleItems = Math.max(SHOW_LESS_COUNT, activeItems.count());
    document.querySelectorAll(`${containerElement} input[type="checkbox"]`)[visibleItems]?.focus();
  },

  /**
   * Generate filters.
   * @returns {Component}
   * @private
   */
  _generateFilters: function () {
    const sorting = this.props.sorting.deref();

    return (
      <div className="SearchPage-filters">
        {!this.state.matchesMobileMediaQuery && <div className="SearchPage-filters-desktop">{this._generateFiltersContent()}</div>}
        <div className="SearchPage-filters-mobile">
          <Button className="Button--medium Button--dark filter-mobile" title="Filters openen" onClick={() => this.props.sidebarOpen.set(true)}>
            <Tune className="tune-icon" />
            <span className="Button-label">Filter</span>
          </Button>
          <SearchSortingOptionsSelect value={sorting} selectIdPrefix={'filters'} onChange={this.handleSortChange} />
        </div>
      </div>
    );
  },

  _generateDateFilter: function (key, label, activeKey) {
    const aggregations = this.props.aggregations.deref();
    const bucket = aggregations?.debateDate?.buckets.find((item) => item.key === key);
    const isActive = activeKey === key;

    if (!bucket) {
      return null;
    }

    const className = classNames('Filter-option-wrapper', {
      'Filter-option-wrapper--active': isActive,
      'Filter-option-wrapper--inactive': !isActive && !!activeKey,
    });

    const handleClick = () => {
      if (isActive) {
        this.setFromAndToDate();
      } else {
        this.setFromAndToDate(bucket.from, bucket.to);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === 'Enter') handleClick();
    };

    return (
      <div className={className} key={label} onKeyUp={handleKeyUp} onClick={handleClick} role="button" tabIndex={0} aria-pressed={isActive}>
        <div>
          <span>{label}</span>
          <p className="option-dates">
            {moment(bucket.from).format('D MMM YYYY')} t/m {moment(bucket.to).format('D MMM YYYY')}
          </p>
        </div>
        <div>
          <p className="result-number">({bucket.doc_count})</p>
        </div>
      </div>
    );
  },

  /**
   * Generate date filters.
   * @returns {Component}
   * @private
   */
  _generateDateFilters: function () {
    const aggregations = this.props.aggregations.deref();
    const buckets = aggregations?.debateDate?.buckets;
    const activeFilters = this.props.activeFilters.deref();
    const fromDate = activeFilters.get('fromDate');
    const toDate = activeFilters.get('toDate');

    let activeKey = false;

    if (buckets) {
      buckets.forEach((bucket) => {
        if (moment(bucket.from).isSame(fromDate, 'day') && moment(bucket.to).isSame(toDate, 'day')) {
          activeKey = bucket.key;
        }
      });
    }

    return (
      <React.Fragment>
        {this._generateDateFilter('this-week', 'Deze week', activeKey)}
        {this._generateDateFilter('next-week', 'Volgende week', activeKey)}
        {this._generateDateFilter('last-week', 'Vorige week', activeKey)}
        {this._generateDateFilter('this-month', 'Deze maand', activeKey)}
        {this._generateDateFilter('past-year', 'Afgelopen jaar', activeKey)}
      </React.Fragment>
    );
  },

  /**
   * Generate date filters.
   * @returns {Component}
   * @private
   */
  _getDatePicker: function () {
    const activeFilters = this.props.activeFilters.deref();

    const fromDate = activeFilters.get('fromDate');
    const fromDateValue = fromDate ? moment(fromDate).format('D MMM YYYY') : '';

    const toDate = activeFilters.get('toDate');
    const toDateValue = toDate ? moment(toDate).format('D MMM YYYY') : '';

    const searchFromDate = moment([2010, 0, 1]).toDate();

    return (
      <div>
        <div>
          <h3 className="option-header">Kies een periode</h3>
        </div>
        <div className="DateFilter">
          <label className="srOnly" htmlFor="start-date-input">
            Startdatum
          </label>
          <input
            id="start-date-input"
            className="DateFilter-input"
            placeholder="Startdatum"
            value={fromDateValue}
            onClick={(event) => this.handleDatePickerClick(event, 'fromDate')}
            onKeyDown={(event) => event.key === 'Enter' && this.handleDatePickerClick(event, 'fromDate')}
            readOnly
          />
          {fromDate ? (
            <Button className="IconButton DateFilter-button" onClick={this.resetFromDateFilter} aria-label="Startdatum legen">
              <Close />
            </Button>
          ) : (
            <Button
              className="IconButton DateFilter-button"
              onClick={(event) => this.handleDatePickerClick(event, 'fromDate')}
              aria-expanded={this.state.datePickerOpen && this.state.datePickerKey === 'fromDate'}
              aria-label="Kies een startdatum"
              aria-controls="start-date-input"
            >
              <Today />
            </Button>
          )}
        </div>
        <div className="DateFilter">
          <label className="srOnly" htmlFor="end-date-input">
            Einddatum
          </label>
          <input
            id="end-date-input"
            className="DateFilter-input"
            placeholder="Einddatum"
            value={toDateValue}
            onClick={(event) => this.handleDatePickerClick(event, 'toDate')}
            onKeyDown={(event) => event.key === 'Enter' && this.handleDatePickerClick(event, 'toDate')}
            readOnly
          />
          {toDate ? (
            <Button className="IconButton DateFilter-button" onClick={this.resetToDateFilter} aria-label="Einddatum legen">
              <Close />
            </Button>
          ) : (
            <Button
              className="IconButton DateFilter-button"
              onClick={(event) => this.handleDatePickerClick(event, 'toDate')}
              aria-expanded={this.state.datePickerOpen && this.state.datePickerKey === 'toDate'}
              aria-label="Kies een einddatum"
              aria-controls="end-date-input"
            >
              <Today />
            </Button>
          )}
        </div>
        <DatePickerPopover
          open={this.state.datePickerOpen}
          title={this.state.datePickerKey === 'fromDate' ? 'Kies een startdatum' : 'Kies een einddatum'}
          anchor={this.state.datePickerAnchor}
          anchorOffset={{
            horizontal: 'right',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'auto',
          }}
          selectedDate={this.state.datePickerValue}
          onChange={this.handleDatePickerChange}
          onClose={() => this.setState({ datePickerOpen: false })}
          disabledDays={[{ after: moment().endOf('week').add(1, 'week').toDate() }]}
          fromDate={searchFromDate}
        />
      </div>
    );
  },

  _getLocationFilter: function (key, checked, count) {
    const query = this.props.query.deref();

    return (
      <label className="Category" key={key}>
        <Checkbox
          className="Category-checkbox"
          value={key}
          checked={checked}
          onChange={(event) => {
            this.context.getService('search').toggleFacet('locationNames', event.currentTarget.value);
            this.context.getService('search').search(query);
            const filters = this.props.aggregations.deref();
            const index = filters?.locationNames?.buckets?.findIndex(({ key }) => key === event.currentTarget.value);
            const isActive = this.context.getService('search').getFacet('locationNames', event.currentTarget.value);
            if (!this.state.showMoreCategories && !isActive && index >= SHOW_LESS_COUNT) {
              this.setState({ showMoreCategories: true });
            }
          }}
        />
        <div className="Category-label">{key}</div>
        <div className="Category-count">({count})</div>
      </label>
    );
  },

  _getLocationFilters: function () {
    const filters = this.props.aggregations.deref();
    const activeFilters = this.props.activeFilters.deref();
    const activeLocationNames = activeFilters.get('locationNames');

    const locationNames = filters?.locationName?.locationName || filters?.locationName;
    const activeItems = (locationNames?.buckets || []).filter(({ key }) => activeLocationNames.includes(key));
    const inactiveItems = (locationNames?.buckets || []).filter(({ key }) => !activeLocationNames.includes(key));
    const items = [...activeItems, ...inactiveItems].slice(0, this.state.showMoreLocations ? 999 : Math.max(SHOW_LESS_COUNT, activeItems.length));

    return <React.Fragment>{items.map(({ key, doc_count }) => this._getLocationFilter(key, activeLocationNames.includes(key), doc_count))}</React.Fragment>;
  },

  /**
   * Generate subject filters.
   * @returns {Array}
   * @private
   */
  _getSubjectFilter: function (key, checked, count) {
    const query = this.props.query.deref();

    return (
      <label className="Category" key={key}>
        <Checkbox
          className="Category-checkbox"
          value={key}
          checked={checked}
          onChange={(event) => {
            this.context.getService('search').toggleFacet('categoryNames', event.currentTarget.value);
            this.context.getService('search').search(query);
            const filters = this.props.aggregations.deref();
            const index = filters?.categoryNames?.buckets?.findIndex(({ key }) => key === event.currentTarget.value);
            const isActive = this.context.getService('search').getFacet('categoryNames', event.currentTarget.value);
            if (!this.state.showMoreCategories && !isActive && index >= SHOW_LESS_COUNT) {
              this.setState({ showMoreCategories: true });
            }
          }}
        />
        <div className="Category-label">{key}</div>
        <div className="Category-count">({count})</div>
      </label>
    );
  },

  _getSubjectFilters: function () {
    const filters = this.props.aggregations.deref();
    const activeFilters = this.props.activeFilters.deref();
    const activeCategoryNames = activeFilters.get('categoryNames');

    const categoryNames = filters?.categoryNames?.categoryNames || filters?.categoryNames;
    const activeItems = (categoryNames?.buckets || []).filter(({ key }) => activeCategoryNames.includes(key));
    const inactiveItems = (categoryNames?.buckets || []).filter(({ key }) => !activeCategoryNames.includes(key));
    const items = [...activeItems, ...inactiveItems].slice(0, this.state.showMoreCategories ? 999 : Math.max(SHOW_LESS_COUNT, activeItems.length));

    return <React.Fragment>{items.map(({ key, doc_count }) => this._getSubjectFilter(key, activeCategoryNames.includes(key), doc_count))}</React.Fragment>;
  },

  _getStatusFilter: function (key, checked, count) {
    const query = this.props.query.deref();

    return (
      <label className="Category" key={key}>
        <Checkbox
          className="Category-checkbox"
          value={key}
          checked={checked}
          onChange={(event) => {
            this.context.getService('search').toggleFacet('status', event.currentTarget.value);
            this.context.getService('search').search(query);
          }}
        />
        <div className="Category-label">{key}</div>
        <div className="Category-count">({count})</div>
      </label>
    );
  },

  _getStatusFilters: function () {
    const filters = this.props.aggregations.deref();
    const activeFilters = this.props.activeFilters.deref();
    const activeStatus = activeFilters.get('status');

    const status = filters?.status?.status || filters?.status;
    const activeItems = Object.entries(status?.buckets || []).filter(([key]) => activeStatus.includes(key));
    const inactiveItems = Object.entries(status?.buckets || []).filter(([key]) => !activeStatus.includes(key));
    const items = [...activeItems, ...inactiveItems];

    return <React.Fragment>{items.map(([key, { doc_count }]) => this._getStatusFilter(key, activeStatus.includes(key), doc_count))}</React.Fragment>;
  },

  _getPartyFilter: function (key, checked, count) {
    const query = this.props.query.deref();

    return (
      <label className="Category" key={key}>
        <Checkbox
          className="Category-checkbox"
          value={key}
          checked={checked}
          onChange={(event) => {
            this.context.getService('search').toggleFacet('partyShorthands', event.currentTarget.value);
            this.context.getService('search').search(query);
            const filters = this.props.aggregations.deref();
            const index = filters?.partyShorthands?.buckets?.findIndex(({ key }) => key === event.currentTarget.value);
            const isActive = this.context.getService('search').getFacet('partyShorthands', event.currentTarget.value);
            if (!this.state.showMoreParties && !isActive && index >= SHOW_LESS_COUNT) {
              this.setState({ showMoreParties: true });
            }
          }}
        />
        <div className="Category-label">{key}</div>
        <div className="Category-count">({count})</div>
      </label>
    );
  },

  _getPartyFilters: function () {
    const filters = this.props.aggregations.deref();
    const activeFilters = this.props.activeFilters.deref();
    const activePartyShorthands = activeFilters.get('partyShorthands');

    const partyShorthands = filters?.partyShorthands?.partyShorthands || filters?.partyShorthands;
    const activeItems = (partyShorthands?.buckets || []).filter(({ key }) => activePartyShorthands.includes(key));
    const inactiveItems = (partyShorthands?.buckets || []).filter(({ key }) => !activePartyShorthands.includes(key));
    const items = [...activeItems, ...inactiveItems].slice(0, this.state.showMoreParties ? 999 : Math.max(SHOW_LESS_COUNT, activeItems.length));

    return <React.Fragment>{items.map(({ key, doc_count }) => this._getPartyFilter(key, activePartyShorthands.includes(key), doc_count))}</React.Fragment>;
  },

  _getPoliticianFilter: function (key, checked, count) {
    const query = this.props.query.deref();

    return (
      <label className="Category" key={key}>
        <Checkbox
          className="Category-checkbox"
          value={key}
          checked={checked}
          onChange={(event) => {
            this.context.getService('search').toggleFacet('politicianNames', event.currentTarget.value);
            this.context.getService('search').search(query);
            const filters = this.props.aggregations.deref();
            const index = filters?.politicianNames?.buckets?.findIndex(({ key }) => key === event.currentTarget.value);
            const isActive = this.context.getService('search').getFacet('politicianNames', event.currentTarget.value);
            if (!this.state.showMorePoliticians && !isActive && index >= SHOW_LESS_COUNT) {
              this.setState({ showMorePoliticians: true });
            }
          }}
        />
        <div className="Category-label">{key}</div>
        <div className="Category-count">({count})</div>
      </label>
    );
  },

  _getPoliticianFilters: function () {
    const filters = this.props.aggregations.deref();
    const activeFilters = this.props.activeFilters.deref();
    const activePoliticianNames = activeFilters.get('politicianNames');

    const politicianNames = filters?.politicianNames?.politicianNames || filters?.politicianNames;
    const activeItems = (politicianNames?.buckets || []).filter(({ key }) => activePoliticianNames.includes(key));
    const inactiveItems = (politicianNames?.buckets || []).filter(({ key }) => !activePoliticianNames.includes(key));
    const items = [...activeItems, ...inactiveItems].slice(0, this.state.showMorePoliticians ? 999 : Math.max(SHOW_LESS_COUNT, activeItems.length));

    return <React.Fragment>{items.map(({ key, doc_count }) => this._getPoliticianFilter(key, activePoliticianNames.includes(key), doc_count))}</React.Fragment>;
  },

  _getDossierFilter: function (key, checked, count) {
    const query = this.props.query.deref();

    return (
      <label className="Category" key={key}>
        <Checkbox
          className="Category-checkbox"
          value={key}
          checked={checked}
          onChange={(event) => {
            this.context.getService('search').toggleFacet('dossierIds', event.currentTarget.value);
            this.context.getService('search').search(query);
            const filters = this.props.aggregations.deref();
            const index = filters?.dossierIds?.buckets?.findIndex(({ key }) => key === event.currentTarget.value);
            const isActive = this.context.getService('search').getFacet('dossierIds', event.currentTarget.value);
            if (!this.state.showMoreDossiers && !isActive && index >= SHOW_LESS_COUNT) {
              this.setState({ showMoreDossiers: true });
            }
          }}
        />
        <div className="Category-label">{key}</div>
        <div className="Category-count">({count})</div>
      </label>
    );
  },

  _getDossierFilters: function () {
    const filters = this.props.aggregations.deref();
    const activeFilters = this.props.activeFilters.deref();
    const activeDossierIds = activeFilters.get('dossierIds');

    const dossierIds = filters?.dossierIds?.dossierIds || filters?.dossierIds;
    const activeItems = (dossierIds?.buckets || []).filter(({ key }) => activeDossierIds.includes(key));
    const inactiveItems = (dossierIds?.buckets || []).filter(({ key }) => !activeDossierIds.includes(key));
    const items = [...activeItems, ...inactiveItems].slice(0, this.state.showMoreDossiers ? 999 : Math.max(SHOW_LESS_COUNT, activeItems.length));

    return <React.Fragment>{items.map(({ key, doc_count }) => this._getDossierFilter(key, activeDossierIds.includes(key), doc_count))}</React.Fragment>;
  },

  renderDateFilterTag: function (date) {
    return (
      <div className="FilterTag" key="DateFilter">
        <span className="FilterTag-label">{date}</span>
        <div
          className="FilterTag-button"
          role="button"
          aria-label="Verwijder filter"
          tabIndex={0}
          onClick={() => this.resetToDateAndFromDateFilter()}
          onKeyDown={(event) => (event.key === 'Enter' || event.key === ' ') && this.resetToDateAndFromDateFilter()}
        >
          <Close />
        </div>
      </div>
    );
  },

  generateActiveDateFilterTag: function () {
    const activeFilters = this.props.activeFilters.deref();
    const toDate = activeFilters.get('toDate');
    const fromDate = activeFilters.get('fromDate');

    if (toDate !== null && fromDate === null) {
      return this.renderDateFilterTag(`Tot ${moment(toDate).format('D MMM YYYY')}`);
    }

    if (toDate === null && fromDate !== null) {
      return this.renderDateFilterTag(`Vanaf ${moment(fromDate).format('D MMM YYYY')}`);
    }

    if (toDate !== null && fromDate !== null) {
      return this.renderDateFilterTag(`${moment(fromDate).format('D MMM YYYY')} - ${moment(toDate).format('D MMM YYYY')}`);
    }

    return null;
  },

  /**
   * Generate tags for active filters.
   * @returns {Component}
   * @private
   */
  _generateActiveFiltersTags: function () {
    const activeFilters = this.props.activeFilters.deref();
    const locationNames = activeFilters.get('locationNames');
    const categoryNames = activeFilters.get('categoryNames');
    const partyShorthands = activeFilters.get('partyShorthands');
    const politicianNames = activeFilters.get('politicianNames');
    const dossierIds = activeFilters.get('dossierIds');
    const status = activeFilters.get('status');
    const fromDate = activeFilters.get('fromDate');
    const toDate = activeFilters.get('toDate');
    const query = this.props.query.deref();
    const hasTags = [categoryNames, partyShorthands, politicianNames, dossierIds, status, locationNames].every((item) => !item.count());

    if (!fromDate && !toDate && hasTags) {
      return null;
    }

    const clearFilter = (activatedFilter, toggleFacet) => {
      this.context.getService('search').toggleFacet(toggleFacet, activatedFilter);
      this.context.getService('search').search(query);
    };

    return (
      <div className="u-flex u-bordered u-borderBottom u-pt5 u-pb5">
        <div className="u-flex-child filter-container">
          {this.generateActiveDateFilterTag()}
          {locationNames.map((activatedFilter) => (
            <div className="FilterTag" key={activatedFilter}>
              <span className="FilterTag-label">{activatedFilter}</span>
              <div
                className="FilterTag-button"
                role="button"
                aria-label="Verwijder filter"
                tabIndex={0}
                onClick={() => clearFilter(activatedFilter, 'locationNames')}
                onKeyDown={(event) => (event.key === 'Enter' || event.key === ' ') && clearFilter(activatedFilter, 'locationNames')}
              >
                <Close />
              </div>
            </div>
          ))}
          {categoryNames.map((activatedFilter) => (
            <div className="FilterTag" key={activatedFilter}>
              <span className="FilterTag-label">{activatedFilter}</span>
              <div
                className="FilterTag-button"
                role="button"
                aria-label="Verwijder filter"
                tabIndex={0}
                onClick={() => clearFilter(activatedFilter, 'categoryNames')}
                onKeyDown={(event) => (event.key === 'Enter' || event.key === ' ') && clearFilter(activatedFilter, 'categoryNames')}
              >
                <Close />
              </div>
            </div>
          ))}
          {partyShorthands.map((activatedFilter) => (
            <div className="FilterTag" key={activatedFilter}>
              <span className="FilterTag-label">{activatedFilter}</span>
              <div
                className="FilterTag-button"
                role="button"
                aria-label="Verwijder filter"
                tabIndex={0}
                onClick={() => clearFilter(activatedFilter, 'partyShorthands')}
                onKeyDown={(event) => (event.key === 'Enter' || event.key === ' ') && clearFilter(activatedFilter, 'partyShorthands')}
              >
                <Close />
              </div>
            </div>
          ))}
          {politicianNames.map((activatedFilter) => (
            <div className="FilterTag" key={activatedFilter}>
              <span className="FilterTag-label">{activatedFilter}</span>
              <div
                className="FilterTag-button"
                role="button"
                aria-label="Verwijder filter"
                tabIndex={0}
                onClick={() => clearFilter(activatedFilter, 'politicianNames')}
                onKeyDown={(event) => (event.key === 'Enter' || event.key === ' ') && clearFilter(activatedFilter, 'politicianNames')}
              >
                <Close />
              </div>
            </div>
          ))}
          {dossierIds.map((activatedFilter) => (
            <div className="FilterTag" key={activatedFilter}>
              <span className="FilterTag-label">{activatedFilter}</span>
              <div
                className="FilterTag-button"
                role="button"
                aria-label="Verwijder filter"
                tabIndex={0}
                onClick={() => clearFilter(activatedFilter, 'dossierIds')}
                onKeyDown={(event) => (event.key === 'Enter' || event.key === ' ') && clearFilter(activatedFilter, 'dossierIds')}
              >
                <Close />
              </div>
            </div>
          ))}
          {status.map((activatedFilter) => (
            <div className="FilterTag" key={activatedFilter}>
              <span className="FilterTag-label">{activatedFilter}</span>
              <div
                className="FilterTag-button"
                role="button"
                aria-label="Verwijder filter"
                tabIndex={0}
                onClick={() => clearFilter(activatedFilter, 'status')}
                onKeyDown={(event) => (event.key === 'Enter' || event.key === ' ') && clearFilter(activatedFilter, 'status')}
              >
                <Close />
              </div>
            </div>
          ))}
        </div>
        <Button
          className="SearchPage-reset"
          onClick={() => {
            this.context.getService('search').resetFacets();
            this.context.getService('search').search(query);
          }}
        >
          Wis alle filters
        </Button>
      </div>
    );
  },

  _generateSearchResult: function (result, index, totalResults) {
    const query = this.props.query.deref();
    const source = result._source;

    const path = this.context.pathTo(`debate-subject`, {
      date: moment(source.debateDate).format('YYYY-MM-DD'),
      category: source.categoryIds?.[0],
      location: source.locationId,
      debate: source.slug,
    });
    const introduction = this.renderHighlightIntroduction(result);

    return (
      <article className="DebateResult" key={source.id} aria-labelledby={`header-${source.id}`} aria-setsize={totalResults} aria-posinset={index + 1}>
        <InternalLink href={path} className="link">
          <h3 id={`header-${source.id}`} className="title" dangerouslySetInnerHTML={{ __html: this.renderHighlightName(result) }} />
          <p className="date">
            {`${moment(source.debateDate).format('DD MMMM YYYY')} | ${moment(source.startsAt).format('HH:mm')} - ${moment(source.endsAt).format('HH:mm')}`}
          </p>
          <p className="location">{`${source.categoryNames[0]} - ${source.debateType} in de ${source.locationName}`}</p>
          {introduction && <p className="descriptionQuote" dangerouslySetInnerHTML={{ __html: introduction }} />}
        </InternalLink>
        {source.events?.length ? <SearchResultSpeechMoments debate={source} searchPhrase={query} events={source.events} /> : null}
      </article>
    );
  },

  /**
   * Generate results filters.
   * @returns {Array}
   * @private
   */
  _generateResults: function () {
    const results = this.props.results.deref();
    const activeFilters = this.props.activeFilters.deref();
    const sorting = this.props.sorting.deref();
    const query = this.props.query.deref();
    const resultSize = results && query !== '' ? results.total.value : -1;

    const categories = activeFilters.get('categoryNames').count();
    const fromDate = activeFilters.get('fromDate');
    const toDate = activeFilters.get('toDate');
    const status = activeFilters.get('status').count();
    const partyShorthands = activeFilters.get('partyShorthands').count();
    const dossierIds = activeFilters.get('dossierIds').count();
    const politicianNames = activeFilters.get('politicianNames').count();
    const locationNames = activeFilters.get('locationNames').count();
    const showResetButton = categories > 0 || fromDate || toDate || status || politicianNames || dossierIds || partyShorthands || locationNames;

    let title = 'Alle zoekresultaten';

    if (this.state.loadingResults) {
      title = ' ';
    } else if (results?.hits && query !== '') {
      title = `${results.total.value} resultaten`;
    }

    return (
      <section className="SearchResults" id="search-results" aria-labelledby="search-results-heading" tabIndex={-1}>
        <div className="SearchPage-header u-bordered u-borderBottom">
          <h2 className="SearchPage-heading Heading" id="search-results-heading">
            {title}
          </h2>
          <div className="SearchPage-header-actions">
            <SearchSortingOptionsSelect selectIdPrefix={'results'} value={sorting} onChange={this.handleSortChange} />
            {!!showResetButton && (
              <Button
                className="SearchPage-reset"
                onClick={() => {
                  this.context.getService('search').resetFacets();
                  this.context.getService('search').search(query);
                }}
              >
                Wis alle filters
              </Button>
            )}
          </div>
        </div>
        <div className="SearchResults-activeFilters">{results?.hits ? this._generateActiveFiltersTags() : null}</div>
        {this.state.loadingResults ? (
          <React.Fragment>
            <p className="option-header-loading">Aan het laden...</p>
            <div className="loader">
              <CircularProgress size={48} />
            </div>
          </React.Fragment>
        ) : results?.hits?.length > 0 ? (
          <React.Fragment>
            <div role="feed" aria-busy={this.state.loadingMore}>
              {results.hits.map((hit, index) => this._generateSearchResult(hit, index, resultSize))}
            </div>
            {this.state.loadingMore ? (
              <div className="loader">
                <CircularProgress size={24} />
              </div>
            ) : null}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h3 className="option-header-geen-resultaten">Geen zoekresultaten gevonden</h3>
            <p>Helaas, wij vonden geen debatten voor uw zoekopdracht</p>
            <p>Suggesties:</p>
            <ul className="suggestions-ul">
              <li>Controleer de spelling van uw zoekopdracht</li>
              <li>Probeer een andere zoekopdracht</li>
            </ul>
          </React.Fragment>
        )}
      </section>
    );
  },

  renderHighlightName: function (hits) {
    if (hits.highlight?.['name.raw']) {
      return hits.highlight['name.raw'][0];
    }

    if (hits.highlight?.name) {
      return hits.highlight.name[0];
    }

    return hits._source.name;
  },

  renderHighlightIntroduction: function (hits) {
    if (hits.highlight?.introduction) {
      return hits.highlight.introduction[0];
    }

    return null;
  },

  handleSearchBarOnChange: function (event, { newValue }) {
    this.context.getCursor().setIn(['ui', 'search', 'query'], newValue);
    this.searchDebounced(newValue);
  },

  handleSearchBarOnSubmit: function () {
    const query = this.props.query.deref();

    this.searchDebounced(query);
  },

  isValidDateString: function (string) {
    return moment(string).isValid();
  },

  handleResize: function () {
    const mobileMediaQuery = window.matchMedia('screen and (max-width: 600px)');
    this.setState({ matchesMobileMediaQuery: mobileMediaQuery.matches });
  },

  componentDidMount: function () {
    const searchService = this.context.getService('search');

    let query = this.props.query.deref();

    this.resizeDebounced = debounce(100, this.handleResize);
    this.searchDebounced = debounce(600, this.search);
    this.visibleWhenScrolledDownDebounced = debounce(250, this.visibleWhenScrolledDown);
    this.handleScrollPersisted = (event) => {
      this.handleScrollDebounced(event);
    };
    this.handleScrollDebounced = debounce(250, this.handleScroll);
    this.handleResize();

    window.addEventListener('resize', this.resizeDebounced);

    const location = this.context.getService('router').history.getCurrentLocation();

    const searchParams = parse(location.search, { arrayFormat: 'index', decode: true });

    if (searchParams.van && this.isValidDateString(searchParams.van)) {
      searchService.setFromDate(searchParams.van);
    }

    if (searchParams.tot && this.isValidDateString(searchParams.tot)) {
      searchService.setToDate(searchParams.tot);
    }

    const activeFilters = this.props.activeFilters.deref();
    const fromDate = searchParams.van || activeFilters.get('fromDate');
    const toDate = searchParams.tot || activeFilters.get('toDate');

    // Auto-select 'Afgelopen jaar' if none is selected
    if (!fromDate && !toDate) {
      const aggregations = this.props.aggregations.deref();
      const pastYearBucket = aggregations?.debateDate?.buckets?.find((item) => item.key === 'past-year');
      const fromMoment = (!!pastYearBucket && moment(pastYearBucket?.from)) || moment().subtract(12, 'months'); // Fallback for when aggregations not yet available
      const toMoment = (!!pastYearBucket && moment(pastYearBucket?.to)) || moment(); // Fallback for when aggregations not yet available

      searchService.setFromDate(fromMoment.format('YYYY-MM-DD'));
      searchService.setToDate(toMoment.format('YYYY-MM-DD'));
    }

    if (searchParams.locatie && Array.isArray(searchParams.locatie)) {
      searchParams.locatie.forEach((item) => searchService.setFacet('locationNames', item, true));
    }

    if (searchParams.categorie && Array.isArray(searchParams.categorie)) {
      searchParams.categorie.forEach((item) => searchService.setFacet('categoryNames', item, true));
    }

    if (searchParams.politici && Array.isArray(searchParams.politici)) {
      searchParams.politici.forEach((item) => searchService.setFacet('politicianNames', item, true));
    }

    if (searchParams.partij && Array.isArray(searchParams.partij)) {
      searchParams.partij.forEach((item) => searchService.setFacet('partyShorthands', item, true));
    }

    if (searchParams.dossier && Array.isArray(searchParams.dossier)) {
      searchParams.dossier.forEach((item) => searchService.setFacet('dossierIds', item, true));
    }

    if (searchParams.status && Array.isArray(searchParams.status)) {
      searchParams.status.forEach((item) => searchService.setFacet('status', item, true));
    }

    if (searchParams.q) {
      query = searchParams.q;

      this.context.getCursor().setIn(['ui', 'search', 'query'], query);
    }

    this.search(query);
  },

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeDebounced);
  },

  componentDidUpdate(prevProps) {
    const sidebarOpen = this.props.sidebarOpen.deref(false);
    const wasSidebarOpen = prevProps.sidebarOpen.deref(false);

    if (sidebarOpen && !wasSidebarOpen) {
      this.lastFocusedElement = document.activeElement;
      window.requestAnimationFrame(() => {
        const sidebar = this.sidebarRef.current;

        sidebar?.querySelectorAll('button, a')[0]?.focus({ preventScroll: true });
      });
    } else if (!sidebarOpen && wasSidebarOpen) {
      this.lastFocusedElement?.focus();
    }
  },

  search: function (query) {
    const searchService = this.context.getService('search');

    this.setState({ loadingResults: true });
    this.context.getService('search').resetSearchFrom();
    searchService.search(query).then(() => {
      this.setState({ loadingResults: false });
    });
  },

  handleSearchBarReset: function () {
    const searchService = this.context.getService('search');

    this.context.getCursor().setIn(['ui', 'search', 'query'], '');
    searchService.setSorting('relevant');
    searchService.search('');
  },

  setFromAndToDate: function (fromDate, toDate) {
    const searchService = this.context.getService('search');
    const query = this.props.query.deref();

    searchService.resetSearchFrom();
    searchService.setFromDate(fromDate);
    searchService.setToDate(toDate);
    searchService.search(query);
  },

  resetToDateAndFromDateFilter: function () {
    const searchService = this.context.getService('search');
    const query = this.props.query.deref();

    searchService.setFromDate(null);
    searchService.setToDate(null);
    searchService.search(query);
  },

  resetFromDateFilter: function () {
    const searchService = this.context.getService('search');
    const query = this.props.query.deref();

    searchService.resetSearchFrom();
    searchService.setFromDate(null);
    searchService.search(query);
  },

  resetToDateFilter: function () {
    const searchService = this.context.getService('search');
    const query = this.props.query.deref();

    searchService.resetSearchFrom();
    searchService.setToDate(null);
    searchService.search(query);
  },

  handleDatePickerClick: function (event, key) {
    const activeFilters = this.props.activeFilters.deref();
    const date = activeFilters.get(key);

    this.setState({
      datePickerOpen: true,
      datePickerAnchor: event.currentTarget,
      datePickerValue: date ? moment(date).toDate() : undefined,
      datePickerKey: key,
    });
  },

  handleDatePickerChange: function (date) {
    const searchService = this.context.getService('search');
    const query = this.props.query.deref();

    if (this.state.datePickerKey === 'toDate') {
      searchService.setToDate(date);
    } else {
      searchService.setFromDate(date);
    }

    this.setState({
      datePickerOpen: false,
    });

    searchService.resetSearchFrom();
    searchService.search(query);
  },

  handleSortChange: function (event) {
    const searchService = this.context.getService('search');
    const query = this.props.query.deref();

    searchService.resetSearchFrom();
    searchService.setSorting(event.currentTarget.value);
    searchService.search(query);
  },

  clearSuggestions: function () {
    this.context.getService('search').resetSuggestions();
  },

  getSuggestions: function (value) {
    this.context.getService('search').getSuggestions(value);
  },

  scrollToTopClick: function () {
    this.setState({ scrollButtonVisible: false });
    this.smartScrollViewRef?.current.scrollToTop();
  },

  visibleWhenScrolledDown: function () {
    if (this.smartScrollViewRef?.current.getPosition().y <= 300) {
      return this.setState({ scrollButtonVisible: false });
    }

    this.setState({ scrollButtonVisible: true });
  },

  loadMoreResults: function () {
    const query = this.props.query.deref();

    this.setState({ loadingMore: true });
    this.context
      .getService('search')
      .loadMore(query)
      .then(() => {
        this.setState({ loadingMore: false });
      });
  },

  handleScroll: function (e) {
    const hasMore = this.props.hasMore.deref();

    if (!hasMore || this.state.loadingMore) {
      return;
    }

    const bottom = e.target.scrollHeight - e.target.scrollTop - 300 <= e.target.clientHeight;

    if (bottom) {
      this.loadMoreResults();
    }
  },
};

/**
 * Render method of search page.
 * @param {Object} props
 * @returns {React.Component}
 */
const render = function () {
  const results = this.props.results.deref();
  const suggestions = this.props.suggestions.deref();
  const query = this.props.query.deref();
  const sidebarOpen = this.props.sidebarOpen.deref();
  const datePickerOpen = this.props.datePickerOpen.deref();

  return (
    <div className="Layer ViewStack-layer">
      <Helmet>
        <title>Zoek debatten | Debat Direct</title>
        <meta name="description" content="Zoek op debat titel en beschrijving" />
      </Helmet>
      <CSSTransition classNames="ScrollToTopButton--animation" timeout={200} in={this.state.scrollButtonVisible} appear enter exit mountOnEnter unmountOnExit>
        <ScrollToTopButton onClick={this.scrollToTopClick} />
      </CSSTransition>
      <div className="Main-wrapper is-expanded" id="main" tabIndex="-1">
        <SmartScrollView ref={this.smartScrollViewRef} onUserScrollEvent={this.visibleWhenScrolledDownDebounced} onScroll={this.handleScrollPersisted}>
          <main role="main" className="Main-content Content">
            <SearchBar
              onReset={this.handleSearchBarReset}
              onChange={this.handleSearchBarOnChange}
              getSuggestions={this.getSuggestions}
              clearSuggestions={this.clearSuggestions}
              query={query}
              suggestions={suggestions || []}
            />
            <div className="SearchPage">
              <SkipLink href={'#search-results'} text="Naar zoekresultaten" className="u-sm-invisible" />
              {this._generateFilters()}
              {this._generateResults()}
            </div>
          </main>
        </SmartScrollView>
      </div>
      <Sidebar className="Sidebar--dark" open={sidebarOpen} onClose={() => this.props.sidebarOpen.set(false)} forwardRef={this.sidebarRef}>
        <SmartScrollView>
          <div className="SearchPage-sidebar" inert={datePickerOpen ? '' : null}>
            <div className="SearchPage-header">
              <h2 className="SearchPage-heading Heading">Verfijn uw resultaten</h2>
              <Button className="IconButton IconButton--no-padding-right" onClick={() => this.props.sidebarOpen.set(false)}>
                <Close />
              </Button>
            </div>
            {this._generateFiltersContent()}
          </div>
        </SmartScrollView>
        <div className="SearchPage-applyButton">
          <Button className="Button--medium Button--primary Button--block" onClick={() => this.props.sidebarOpen.set(false)}>
            <span className="Button-label">{!this.state.loadingResults ? (results?.hits ? `${results.total.value} Resultaten` : '0 Resultaten') : ' '}</span>
          </Button>
        </div>
      </Sidebar>
    </div>
  );
};

/**
 * Public exports.
 */
const Search = component('Search', definition, render);

export default observer(
  structure,
  {
    query: ['ui', 'search', 'query'],
    sidebarOpen: ['ui', 'search', 'sidebarOpen'],
    datePickerOpen: ['ui', 'datePicker', 'open'],
    activeFilters: ['ui', 'search', 'activeFilters'],
    sorting: ['ui', 'search', 'sorting'],
    searchFrom: ['ui', 'search', 'searchFrom'],
    results: ['data', 'search', 'results'],
    aggregations: ['data', 'search', 'aggregations'],
    suggestions: ['data', 'search', 'suggestions'],
    hasMore: ['data', 'search', 'hasMore'],
  },
  Search,
);
