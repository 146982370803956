import { useAppProvider } from '@debatdirect/core-ts/lib/AppProvider';
import type { AgendaResponse } from '@debatdirect/typings';
import type { AppAgendaDebate } from '@debatdirect/core-ts/types/debate';
import { transformDebateData } from '@debatdirect/core-ts/utils/debate';

import useApiData from './useApiData';

const startDateCompareFn = (debateA: AppAgendaDebate, debateB: AppAgendaDebate) => {
  return (debateA.startedAt || debateA.startsAt).getTime() - (debateB.startedAt || debateB.startsAt).getTime();
};

const useAgendaWeeks = () => {
  const { getService } = useAppProvider();
  const api = getService('api');

  return useApiData({
    key: ['agendaWeeks'],
    queryFn: () => api.getAgendaWeeks(),
    transformFn: (agenda: AgendaResponse): AppAgendaDebate[] => {
      return agenda?.debates.map(transformDebateData).sort(startDateCompareFn) ?? [];
    },
  });
};

export default useAgendaWeeks;
