import { type FC, useState, useRef, useEffect } from 'react';
import Button from '@debatdirect/core-ts/components/Button/Button';
import { useAppProvider } from '@debatdirect/core-ts/lib/AppProvider';
import Switch from '@debatdirect/core-ts/components/Switch/Switch';

import AgendaIcon from './icons/AgendaIcon';
import HomeIcon from './icons/HomeIcon';
import styles from './PromotionBanner.module.scss';

type Props = {
  feedbackLink: string;
};

const PromotionBanner: FC<Props> = ({ feedbackLink }) => {
  const { getService } = useAppProvider();
  const userPreferences = getService('user-preferences');
  const hasTrackedEvent = useRef(false); // Prevents firing tracking event multiple times
  const isTreatmentGroup = userPreferences.getTreatmentGroup();
  const [optout, setOptout] = useState<boolean>(userPreferences.getHomepageOptOut());

  useEffect(() => {
    if (optout === undefined) {
      setOptout(false); // When visiting the page for the first time, default to opt-in
    }
    getService('user-preferences').setHomepageOptOut(optout);
  }, [optout, getService]);

  const handleOptOutToggle = () => {
    const newValue = !optout;
    const trackEvent = newValue && !hasTrackedEvent.current;
    setOptout(newValue);

    if (trackEvent && window._paq) {
      hasTrackedEvent.current = true;
      window._paq.push(['trackEvent', 'homepage', 'preferAgendaPage', isTreatmentGroup ? 'treatmentGroup' : 'controlGroup']);
    }
  };

  return (
    <section className={styles.section}>
      <div className={styles.block}>
        <div className={styles.blockIcon}>
          <AgendaIcon aria-hidden="true" />
        </div>
        <div className={styles.blockText}>
          <h2 className={styles.blockTitle}>De agenda blijft beschikbaar</h2>
          <div className={styles.blockParagraph}>
            <p>U kunt eenvoudig naar de agenda gaan via het menu of met de knop hieronder.</p>
            <p>
              <Switch
                name="optout"
                checked={optout}
                onChange={handleOptOutToggle}
                label="Stel Debatten van vandaag in als startscherm in plaats van de nieuwe homepage"
              />
            </p>
          </div>
          <Button href="/" label="Terug naar de agenda" variant="light" className={styles.blockButton} />
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.blockIcon}>
          <HomeIcon aria-hidden="true" />
        </div>
        <div className={styles.blockText}>
          <h2 className={styles.blockTitle}>Ontdek de nieuwe homepage!</h2>
          <div className={styles.blockParagraph}>
            <p>We hebben de homepagina van Debat Direct vernieuwd met meer nadruk op live debatten en visuele weergave van debatten in een moderne weergave.</p>
            <p>Wat vindt u ervan? Vul de enquête in en laat het ons weten.</p>
          </div>
          <Button href={feedbackLink} target="_blank" label="Geef feedback" variant="light" className={styles.blockButton} />
        </div>
      </div>
    </section>
  );
};

export default PromotionBanner;
