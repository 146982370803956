import React, { useEffect, useState } from 'react';

type Props = {
  image: string | null;
  alt?: string;
  className?: string;
};

const Image = ({ image, alt = '', className }: Props) => {
  const [src, setSrc] = useState<string | null>(null);

  useEffect(() => {
    setSrc(image);
  }, [image]);

  if (!src) return null;

  return <img src={src} className={className} alt={alt} />;
};

export default React.memo(Image);
