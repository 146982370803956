import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAppProvider } from '@debatdirect/core-ts/lib/AppProvider';
import useAgendaWeeks from '@debatdirect/core-ts/hooks/useAgendaWeeks';
import { transformDebateData } from '@debatdirect/core-ts/utils/debate';
import { parseISO } from 'date-fns';
import { AgendaDebate } from '@debatdirect/typings';

type DebateWatched = {
  pdt: string;
  debateId: string;
  watchMoment: string;
  progress?: number;
};

const MAX_ITEMS = 10;

const useWatchHistory = () => {
  const { getService } = useAppProvider();
  const { data, isLoading } = useAgendaWeeks();
  const api = getService('api');
  const debates = data || [];
  const enabled = !isLoading;
  const watchHistory = useMemo<DebateWatched[]>(() => {
    // TODO rewrite without using continue-watching factory
    // TODO make reactive to changes within the watch history
    return getService('continue-watching').getHistory();
  }, [getService]);

  const fetchDebates = async () => {
    const mostRecentlyWatchedItems = watchHistory
      .filter((debate) => !debate.progress || (debate.progress >= 0.05 && debate.progress <= 0.95))
      .sort((a, b) => parseISO(b.watchMoment).getTime() - parseISO(a.watchMoment).getTime())
      .slice(0, MAX_ITEMS);
    const debatesDict = Object.fromEntries(debates.map((debate) => [debate.id, debate]));

    const promises = mostRecentlyWatchedItems.map(async (debateWatched) => {
      const { debateId } = debateWatched;
      if (debatesDict[debateId]) {
        return Promise.resolve(debatesDict[debateId]);
      }
      const debate = (await api.getDebateSummary(debateId)) as AgendaDebate;
      return transformDebateData(debate);
    });

    return await Promise.all(promises);
  };

  const debateIds = watchHistory.map((item) => item.debateId);
  const { data: watchHistoryDebates } = useQuery({
    enabled,
    queryKey: ['watchHistory', ...[debateIds]],
    placeholderData: [],
    queryFn: fetchDebates,
  });

  const getProgress = (debateId: string) => {
    return watchHistory.find((debate) => debate.debateId === debateId)?.progress || 0;
  };

  return {
    debates: watchHistoryDebates,
    getProgress,
  };
};

export default useWatchHistory;
