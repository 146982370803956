import { add, format, isAfter, parseISO } from 'date-fns';
import type { AgendaDebate, DebateResponse } from '@debatdirect/typings';
import type { AppAgendaDebate, AppDebate } from '@debatdirect/core-ts/types/debate';

import type { ScreenshotConfig } from '../types/app';

/**
 * Rounds the seconds component of a Date object to the nearest specified interval.
 */
export const roundSecondsInterval = (date: Date, secondsInterval: number): Date => {
  const rounded = Math.floor(date.getSeconds() / secondsInterval) * secondsInterval;
  const newDate = new Date(date);

  newDate.setSeconds(rounded);

  return newDate;
};

const getScreenshotMoment = ({ status, startedAt, endedAt }: AppDebate | AppAgendaDebate, config: ScreenshotConfig) => {
  const { offsetInSeconds, refreshInterval } = config;

  switch (status) {
    case 'planned':
      return null;
    case 'live':
      return roundSecondsInterval(new Date(), refreshInterval);
    case 'vod': {
      const screenshotMoment = add(startedAt, { seconds: offsetInSeconds });

      return isAfter(screenshotMoment, endedAt) ? startedAt : screenshotMoment; // Fail-safe
    }
  }
};

export const getDebateScreenshotUri = (debate: AppDebate | AppAgendaDebate, prefix: string | undefined, config: ScreenshotConfig) => {
  const { bitrate, fileType } = config;
  const screenshotMoment = getScreenshotMoment(debate, config);

  if (!screenshotMoment || !prefix) return null;

  const screenshotDate = format(screenshotMoment, 'yyyy-MM-dd'); // I.E.: 2021-12-31
  const screenshotTime = format(screenshotMoment, 'HH:mm:ssxx'); // I.E.: 23:56:50+0100

  return `${prefix}/${bitrate}/${screenshotDate}/${screenshotTime}.${fileType}`;
};

export const getDebateMetadata = (debate: AppDebate | AppAgendaDebate) => {
  const date = format(debate.debateDate, 'd MMM');
  const startTime = format(debate.startedAt || debate.startsAt, 'HH:mm');
  const endTime = format(debate.endedAt || debate.endsAt, 'HH:mm');
  const runningTime = `${startTime} - ${endTime}`;
  let description = '';
  if (!debate.locationName) {
    description = debate.debateType;
  } else if (debate.categoryNames.length === 0) {
    description = `${debate.debateType} in de ${debate.locationName}`;
  } else {
    description = `${debate.categoryNames.join(' - ')} in de ${debate.locationName}`;
  }
  return [date, runningTime, description];
};

export const determineDebateStatus = ({ startedAt, endedAt }: DebateResponse | AgendaDebate) => {
  if (!startedAt) return 'planned';
  if (!endedAt) return 'live';

  return 'vod';
};

export const transformDebateData = <T extends DebateResponse | AgendaDebate, R = T extends DebateResponse ? AppDebate : AppAgendaDebate>(debate: T) => {
  return {
    ...debate,
    debateDateStr: debate.debateDate,
    debateDate: parseISO(debate.debateDate),
    startsAt: parseISO(debate.startsAt),
    endsAt: debate.endsAt ? parseISO(debate.endsAt) : null,
    startedAt: debate.startedAt ? parseISO(debate.startedAt) : null,
    endedAt: debate.endedAt ? parseISO(debate.endedAt) : null,
    status: determineDebateStatus(debate),
  } as R;
};
