import { useAppProvider } from '@debatdirect/core-ts/lib/AppProvider';
import type { DebateLocation, AppResponse } from '@debatdirect/typings';
import type { AppLocationsDict } from '@debatdirect/core-ts/types/app';

import useApiData from './useApiData';

const createLocationDictionary = (locations: DebateLocation[]): AppLocationsDict => {
  return Object.fromEntries(Object.entries(locations).map(([_, location]) => [location.id, location]));
};

const useApp = () => {
  const { config } = useAppProvider();

  return useApiData({
    key: ['app'],
    queryFn: async () => {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      const response = await fetch(`${config.api.baseUrl}/app`, { headers });
      return response.json();
    },
    transformFn: (app: AppResponse) => {
      return { locations: createLocationDictionary(app?.locations) };
    },
  });
};

export default useApp;
