import React, { type FC, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import CircularProgress from '@debatdirect/core/components/Progress/CircularProgress';
import Shelf from '@debatdirect/core-ts/components/Shelf/Shelf';
import FeaturedShelf from '@debatdirect/core-ts/components/FeaturedShelf/FeaturedShelf';
import useAgendaWeeks from '@debatdirect/core-ts/hooks/useAgendaWeeks';
import useWatchHistory from '@debatdirect/core-ts/hooks/useWatchHistory';
import useApp from '@debatdirect/core-ts/hooks/useApp';
import { useAppProvider } from '@debatdirect/core-ts/lib/AppProvider';

import { groupByDateRange, filterTodayByLocation, formatDebateDateRangeStr, ShelfType, filterFeatured, createSearchUrl, TShelf } from './utils/shelf';
import PromotionBanner from './PromotionBanner/PromotionBanner';
import styles from './Home.module.scss';

const Home: FC = () => {
  const baseUrl = import.meta.env.REACT_APP_BASE_URL;
  const feedbackLink = import.meta.env.REACT_APP_FEEDBACK_TOOL_URL;
  const { getService } = useAppProvider();
  const { data: debates, isLoading } = useAgendaWeeks();
  const { debates: watchHistoryDebates = [] } = useWatchHistory();
  const appQuery = useApp();
  const locations = appQuery?.data?.locations;

  useEffect(() => {
    // Set this page as default homepage, for navbar back button
    getService('router').setHomeRoute('home-new');
  }, [getService]);

  const featuredDebates = useMemo(() => {
    return filterFeatured(debates || []);
  }, [debates]);

  const shelves = useMemo(() => {
    const plenaryDebates = filterTodayByLocation(debates || [], 'plenaire-zaal');
    const datedShelves = groupByDateRange(debates || []);
    const plenaryShelves: TShelf[] =
      plenaryDebates.length > 0
        ? [
            {
              title: 'Vandaag in de plenaire zaal',
              shelfType: ShelfType.TODAY_PLENARY,
              debates: plenaryDebates,
              locationId: 'plenaire-zaal',
            },
          ]
        : [];

    // Extract live shelf
    const liveIndex = datedShelves.findIndex((shelf) => shelf.shelfType === ShelfType.LIVE);
    const liveShelf = liveIndex !== -1 ? datedShelves.splice(liveIndex, 1)[0] : null;

    // Combine shelves and sort with predefined order
    const sortedShelves = [...plenaryShelves, ...datedShelves].sort((a, b) => {
      const sortBy = [ShelfType.LIVE, ShelfType.SOON, ShelfType.EARLIER_TODAY, ShelfType.TODAY_PLENARY, ShelfType.YESTERDAY, ShelfType.EARLIER_THIS_WEEK];
      return sortBy.indexOf(a.shelfType) - sortBy.indexOf(b.shelfType);
    });

    return { other: sortedShelves, live: liveShelf };
  }, [debates]);

  return (
    <div className="Layer ViewStack-layer">
      <Helmet>
        <title>Deze week in de Tweede Kamer | Debat Direct</title>
        <link rel="canonical" href={baseUrl} />
      </Helmet>
      <div className="Main-wrapper is-expanded" id="main" tabIndex={-1}>
        <main role="main" className="Main-content Main-content--full Content">
          {isLoading ? (
            <div className={styles.loading}>
              <p>Aan het laden...</p>
              <CircularProgress size={48} />
            </div>
          ) : (
            <>
              <FeaturedShelf debateLeft={featuredDebates.large} debatesRight={featuredDebates.other} locations={locations} />
              {shelves.live && (
                <Shelf
                  title={shelves.live.title}
                  subtitle={formatDebateDateRangeStr(shelves.live.debates)}
                  debates={shelves.live.debates}
                  locations={locations}
                />
              )}
              {feedbackLink && <PromotionBanner feedbackLink={feedbackLink} />}
              {watchHistoryDebates.length > 0 && <Shelf title="Hervatten" debates={watchHistoryDebates} locations={locations} showProgressIndicator={true} />}
              {shelves.other.map((shelf) => (
                <React.Fragment key={shelf.shelfType}>
                  <hr aria-hidden="true" className={styles.divider} />
                  <Shelf
                    title={shelf.title}
                    subtitle={formatDebateDateRangeStr(shelf.debates)}
                    link={createSearchUrl(shelf.debates, shelf.locationId, shelf.status)}
                    debates={shelf.debates}
                    locations={locations}
                  />
                </React.Fragment>
              ))}
            </>
          )}
        </main>
      </div>
    </div>
  );
};

export default Home;
