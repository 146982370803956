import React, { type FC, memo } from 'react';
import { useAppProvider } from '@debatdirect/core-ts/lib/AppProvider';
import type { AppAgendaDebate } from '@debatdirect/core-ts/types/debate';
import type { DebateLocation } from '@debatdirect/typings';
import { getDebateScreenshotUri, getDebateMetadata } from '@debatdirect/core-ts/utils/debate';
import StatusTag from '@debatdirect/core-ts/components/StatusTag/StatusTag';
import Image from '@debatdirect/core-ts/components/Image/Image';
import { navigate } from '@debatdirect/core-ts/utils/routing';
import InternalLink from '@debatdirect/core-ts/components/InternalLink/InternalLink';
import useWatchHistory from '@debatdirect/core-ts/hooks/useWatchHistory';

import styles from './Card.module.scss';

type Props = {
  debate: AppAgendaDebate;
  location?: DebateLocation;
  link: string;
  tabIndex?: number;
  showProgress?: boolean;
};

const Card: FC<Props> = ({ debate, location, link, tabIndex, showProgress }) => {
  const { config } = useAppProvider();
  const { getProgress } = useWatchHistory();
  const alt = ''; // Intentionally empty because of adject text alternative
  const progress = showProgress && debate.status === 'vod' ? Math.round(getProgress(debate.id) * 100) : null;
  const metadata = getDebateMetadata(debate);
  const imageUri = getDebateScreenshotUri(debate, location?.dynamicThumbnailUrl, config.screenshot) || location?.cardImageUrl || null;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      navigate(link);
    }
  };

  return (
    <InternalLink role="button" href={link} className={styles.card} onKeyDown={handleKeyDown} tabIndex={tabIndex}>
      <div className={styles.description}>
        <h3 className={styles.title}>{debate.name}</h3>
        <p className={styles.meta}>
          {metadata.map((value, index) => (
            <React.Fragment key={value}>
              <span className={styles.separator}>{value}</span>
              {index < metadata.length - 1 && <span aria-hidden="true">•</span>}
            </React.Fragment>
          ))}
        </p>
      </div>
      <div className={styles.poster}>
        <Image image={imageUri} alt={alt} className={styles.image} />
        <StatusTag debate={debate} className={styles.tag} />
        {!!progress && <progress value={progress} max="100" className={styles.progress}></progress>}
      </div>
    </InternalLink>
  );
};

export default memo(Card);
